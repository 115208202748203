import React from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserNotificationManager } from 'react-notifications'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label } from '~/ui/components'
import { useViewState } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'

const BrowserNotificationsBar = memo('BrowserNotificationsBar', () => {

  const [askedForPermissions, setAskedForPermissions] = useViewState('notifications.asked-for-permissions', false)
  const permission = BrowserNotificationManager.permission

  const [t] = useTranslation('notifications')

  const requestPermission = React.useCallback(() => {
    BrowserNotificationManager.requestPermission()
    setAskedForPermissions(true)
  }, [setAskedForPermissions])

  const dontAskAgain = React.useCallback(() => {
    setAskedForPermissions(true)
  }, [setAskedForPermissions])

  const $ = useStyles()

  if (askedForPermissions || permission == null) { return null }

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.l} classNames={$.BrowserNotificationsBar}>
        <Label small flex>
          {t('prompt')}
        </Label>
        <HBox gap={layout.padding.inline.m}>
          <ClearButton
            caption={t('enable')}
            onTap={requestPermission}
            small
          />
          <ClearButton
            icon='cross'
            caption={t('dont_ask_again')}
            onTap={dontAskAgain}
            small
          />
        </HBox>
      </HBox>
    )
  }

  return render()

})

export default BrowserNotificationsBar

const useStyles = createUseStyles(theme => ({
  BrowserNotificationsBar: {
    padding:    [layout.padding.inline.xs, layout.padding.inline.m],
    background: theme.bg.normal,
  },
}))