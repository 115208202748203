import React from 'react'
import { useSize } from 'react-measure'
import { Media } from '~/models'
import { memo } from '~/ui/component'
import { Grid, VBox } from '~/ui/components'
import { useLightbox } from '~/ui/components/lightbox'
import PostMediaThumbnail from './PostMediaThumbnail'

export interface Props {
  media: Media[]

  requestRemoveMedia?: (media: Media) => any
}

const PostMediaGallery = memo('PostMediaGallery', (props: Props) => {

  const {media, requestRemoveMedia} = props

  const containerRef = React.useRef<HTMLDivElement>(null)
  const [width, setWidth] = React.useState<number>(0)
  const height = width / 16 * 9

  const count = media.length

  useSize(containerRef, size => { setWidth(size.width) })

  const layout = cellLayout.get(media.length)!

  const {show, setContents} = useLightbox()

  const showLightbox = React.useCallback((media: Media) => {
    const index = props.media.findIndex(it => it.url === media.url)
    if (index < 0) { return }

    setContents(props.media.map(it => (
      it.contentType.startsWith('image') ? {
        type: 'image',
        image: {type: 'remote', url: it.url},
        alt: null,
      } : {
        type:    'video',
        url:     it.url,
        caption: null,
      }
    )))
    show(index)
  }, [props.media, setContents, show])

  //------
  // Rendering

  function render() {
    return (
      <Grid
        ref={containerRef}
        columns={`repeat(2, 1fr)`}
        rows={`repeat(2, 1fr)`}
        style={{height}}
      >
        {media.map(renderMedia)}
      </Grid>
    )
  }

  function renderMedia(media: Media, index: number) {
    const style: React.CSSProperties = {
      gridRow:    `${layout[index][0]} / span ${layout[index][2]}`,
      gridColumn: `${layout[index][1]} / span ${layout[index][3]}`,
    }

    return (
      <VBox key={index} style={style}>
        <PostMediaThumbnail
          media={media}
          requestRemove={requestRemoveMedia}
          onTap={showLightbox}
          objectFit={count === 1 ? 'contain' : 'cover'}
          flex
        />
      </VBox>
    )

  }

  return render()

})

export default PostMediaGallery

const cellLayout = new Map<number, Array<[number, number, number, number]>>([
  [1, [[1, 1, 2, 2]]], // Full
  [2, [[1, 1, 2, 1], [1, 2, 2, 1]]], // Side by side
  [3, [[1, 1, 2, 1], [1, 2, 1, 1], [2, 2, 1, 1]]], // Left long, right on top of each other
  [4, [[1, 1, 1, 1], [1, 2, 1, 1], [2, 1, 1, 1], [2, 2, 1, 1]]], // Two by two
])