import { action, when } from 'mobx'
import { Document, DocumentOptions } from 'mobx-document'
import { bewizrAPI } from '~/apis/bewizr'
import { BeWizrLibraryTopic } from '~/models'
import bewizrLibraryStore from '../bewizrLibraryStore'
import bewizrStore from '../bewizrStore'
import BeWizrLibraryCategoriesEndpoint from './BeWizrLibraryCategoriesEndpoint'
import BeWizrLibraryItemsEndpoint from './BeWizrLibraryItemsEndpoint'

export default class BeWizrLibraryTopicDocument extends Document<BeWizrLibraryTopic> {

  constructor(
    public readonly id: string,
    options: DocumentOptions<BeWizrLibraryTopic> = {},
  ) {
    super(id, options)
    this.categoriesEndpoint = new BeWizrLibraryCategoriesEndpoint(bewizrLibraryStore.categories, {
      topic: this.slug,
    })

    this.itemsEndpoint = new BeWizrLibraryItemsEndpoint(bewizrLibraryStore.items, {
      topic: this.slug,
    })
  }

  public get slug() {
    return this.id
  }

  public categoriesEndpoint: BeWizrLibraryCategoriesEndpoint
  public itemsEndpoint:      BeWizrLibraryItemsEndpoint

  @action
  protected async performFetch() {
    await when(() => bewizrStore.authenticated)

    const api = bewizrAPI()
    if (api == null) { return null }

    const response = await api.get('library_topic/', {
      params: {
        topic: this.slug,
      },
    })
    if (response.status !== 200) { return response }

    const raw = response.data.items.find((it: any) => it.slug === this.slug)
    if (raw == null) { return null }

    const topic = BeWizrLibraryTopic.deserialize(raw)

    return {
      data: topic,
      meta: {},
    }
  }

}
