import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router'
import { profileStore, projectStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  EmptyOrFetching,
  HBox,
  PushButton,
  Scroller,
  TextBlock,
  TextField,
  VBox,
} from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import { createUseStyles, layout } from '~/ui/styling'
import { responsiveFontStyle } from '~/ui/styling/fonts'
import { screenMinWidths } from '~/ui/styling/layout'
import { AgendaWidget, InfoMenuWidget } from './widgets'

export interface Props {

}

const HomeScreen = observer('HomeScreen', (props: Props) => {

  const [t] = useTranslation('home')

  const config      = projectStore.project?.homeScreen
  const participant = profileStore.participant

  const history = useHistory()

  const [query, setQuery] = React.useState<string | null>(null)

  const search = React.useCallback(() => {
    if (query == null) { return null }

    history.push(`/search?query=${encodeURIComponent(query)}`)
  }, [history, query])

  const isMobile = !useMediaQuery({minWidth: screenMinWidths.tablet})

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={t('title')}
        />
        {config == null ? renderEmpty() : (
          <Scroller flex contentClassNames={$.HomeScreen}>
            <VBox gap={layout.padding.xl}>
              {(config.search || config.welcome.enabled) && (
                <VBox gap={layout.padding.m}>
                  {renderMessage()}
                  {renderSearch()}
                </VBox>
              )}
              {config.infoMenu && <InfoMenuWidget/>}
              {config.agenda && <AgendaWidget/>}
            </VBox>
          </Scroller>
        )}
      </>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status='fetching'
      />
    )
  }

  function renderMessage() {
    if (config == null || !config.welcome.enabled) { return null }

    return (
      <TextBlock bold classNames={$.message} markup>
        {config.welcome.message ?? t('welcome', participant)}
      </TextBlock>
    )
  }

  function renderSearch() {
    if (!config?.search) { return null }

    return (
      <form onSubmit={search}>
        <HBox gap={layout.padding.inline.m}>
          <TextField
            classNames={$.searchField}
            value={query ?? ''}
            onChange={setQuery}
            placeholder={t('search.placeholder')}
          />
          <PushButton
            aria-label={t('search.button')}
            caption={isMobile ? undefined : t('search.button')}
            icon='search'
            submit
          />
        </HBox>

      </form>
    )
  }

  return render()

})

export default HomeScreen

const useStyles = createUseStyles(theme => ({
  HomeScreen: {
    ...layout.responsive(size => ({
      padding:    layout.padding.m[size],
      paddingTop: layout.padding.l[size],
    })),
  },

  message: {
    ...responsiveFontStyle(theme.fonts.h1),
  },

  searchField: {
    flexGrow: 1,
  },
}))