import React from 'react'
import { RouteComponentProps } from 'react-router'
import { bewizrMeetingsStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Scroller } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import { ErrorScreen } from '../errors'
import BeWizrMeetingView from './BeWizrMeetingView'

export interface BeWizrMeetingParams {
  id:           string
  occurrenceID?: string
}

export type Props = RouteComponentProps<BeWizrMeetingParams>

const BeWizrMeetingScreen = observer('BeWizrMeetingScreen', (props: Props) => {

  const {id, occurrenceID} = props.match.params

  const document    = bewizrMeetingsStore.meetings.document(id)
  const meeting     = document.data
  const fetchStatus = document.fetchStatus

  const occurrence   = React.useMemo(() => {
    if (occurrenceID == null) { return }
    return meeting?.occurrences.find(occurrence => occurrence.id === occurrenceID)
  }, [meeting, occurrenceID])

  React.useEffect(() => {
    document?.fetch()
  }, [document])

  //------
  // Rendering

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={meeting?.title}
          fetchStatus={fetchStatus}
        />
        {renderContent()}
      </>
    )
  }

  function renderContent() {
    if (meeting == null || fetchStatus !== 'done' || (occurrenceID != null && occurrence == null)) {
      return renderEmpty()
    }

    return (
      <Scroller>
        <BeWizrMeetingView
          meeting={meeting}
          occurrence={occurrence}
        />
      </Scroller>
    )
  }

  function renderEmpty() {
    if (fetchStatus === 'done' && occurrenceID != null && occurrence == null) {
      return (
        <ErrorScreen
          status={404}
        />
      )
    }
    return (
      <EmptyOrFetching
        flex
        status={fetchStatus}
      />
    )
  }

  return render()

})

export default BeWizrMeetingScreen