import React from 'react'
import { useTranslation } from 'react-i18next'
import { combinedFetchStatus } from 'mobx-document'
import { BeWizrCourse, BeWizrLearningTrack } from '~/models'
import { bewizrCoursesStore, bewizrLearningTracksStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Scroller, VBox } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import { layout } from '~/ui/styling'
import { BeWizrSection } from '../bewizr'
import BeWizrLearningTrackTile from '../bewizr-learning-tracks/BeWizrLearningTrackTile'
import BeWizrGrid from '../bewizr/BeWizrGrid'
import BeWizrCourseTile from './BeWizrCourseTile'

const BeWizrCoursesScreen = observer('BeWizrCoursesScreen', () => {

  const [t] = useTranslation()

  const learningTracksEndpoint = bewizrLearningTracksStore.myLearningTracks
  const coursesEndpoint        = bewizrCoursesStore.myCourses

  const fetch = React.useCallback(() => {
    learningTracksEndpoint.fetch()
    coursesEndpoint.fetch()
  }, [learningTracksEndpoint, coursesEndpoint])

  React.useEffect(() => {
    fetch()
  }, [fetch])

  const fetchStatus = combinedFetchStatus(learningTracksEndpoint.fetchStatus, coursesEndpoint.fetchStatus)

  //------
  // Rendering

  const empty = fetchStatus !== 'done' || (learningTracksEndpoint.empty && coursesEndpoint.empty)

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={t('title')}
          fetchStatus={fetchStatus}
        />
        {empty ? renderEmpty() : renderBody()}
      </>
    )
  }

  function renderBody() {
    return (
      <Scroller contentPadding={layout.padding.m}>
        <VBox gap={layout.padding.xl}>
          {learningTracksEndpoint.data.length > 0 && (
            <BeWizrSection title={t('bewizr-learning-tracks:grid.title')}>
            <BeWizrGrid
              endpoint={learningTracksEndpoint}
              renderItem={renderLearningTrack}
            />
          </BeWizrSection>
          )}
          {coursesEndpoint.data.length > 0 && (
            <BeWizrSection title={t('bewizr-courses:grid.title')}>
            <BeWizrGrid
              endpoint={coursesEndpoint}
              renderItem={renderCourse}
            />
          </BeWizrSection>
          )}
        </VBox>
      </Scroller>
    )
  }

  function renderCourse(course: BeWizrCourse) {
    return (
      <BeWizrCourseTile
        title={course.title}
        slug={course.slug}
        image={course.links.image}
        progress={course.progress}
      />
    )
  }

  function renderLearningTrack(learningTrack: BeWizrLearningTrack) {
    return (
      <BeWizrLearningTrackTile
        title={learningTrack.title}
        id={learningTrack.id}
        image={learningTrack.links.image}
        itemCount={learningTrack.itemCount}
      />
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={fetchStatus}
        flex
      />
    )
  }

  return render()

})

export default BeWizrCoursesScreen