import { Database, Endpoint, EndpointOptions } from 'mobx-document'
import { ScopedSocket } from 'socket.io-react'
import { sparse } from 'ytil'
import { Feed, Media, Post } from '~/models'
import PostDocument from './PostDocument'

export default class PostsEndpoint extends Endpoint<PostDocument, PostsParams, PostsMeta> {

  constructor(
    protected readonly socket: ScopedSocket,
    database: Database<PostDocument>,
    feedIDs?: string[],
    options: EndpointOptions<PostDocument, PostsMeta> = {},
  ) {
    super(database, {feedIDs}, options)
  }

  public async fetchMore(params: PostsParams = {}) {
    if (this.meta?.nextPageToken == null) { return }

    return this.fetchWithParams({
      ...params,
      pageToken: this.meta.nextPageToken,
    }, {
      append: true,
    })
  }

  protected async performFetch(params: PostsParams) {
    const response = await this.socket.fetch('posts:fetch', params)
    if (response.ok) {
      return this.enrichPosts(response.body.data, response.body.meta)
    } else {
      return response
    }
  }

  public enrichPosts(data: AnyObject, meta: AnyObject): {data: Post[], meta: PostsMeta} {
    const {
      authors,
      feeds,
      media: allMedia,
      ...rest
    } = meta

    const posts = sparse((data as any[]).map((raw): Post | null => {
      const author = authors.find((it: any) => it.id === raw.author)
      const feed   = feeds.find((it: any) => it.id === raw.feed)
      const media  = sparse((raw.media ?? []).map((id: string) => allMedia.find((it: any) => it.id === id)))
      if (author == null || feed == null) { return null }

      const post  = Post.deserialize({
        ...raw,
        author: author,
        feed:   Feed.deserialize(feed),
        media:  media.map((it: any) => Media.deserialize(it)),
      })

      return post
    }))

    return {data: posts, meta: rest as PostsMeta}
  }

}

export interface PostsParams {
  feedIDs?:   string[]
  root?:      string
  since?:     number
  pageToken?: string
  limit?:     number
  query?:     string
}

export interface PostsMeta {
  total:         number
  nextPageToken: string | null
}