import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import Toast from 'react-toast'
import { every } from 'lodash'
import { DateTime } from 'luxon'
import { NodeHtmlMarkdown } from 'node-html-markdown'
import { BeWizrLearningTrack, BeWizrLearningTrackItem, RemoteImage } from '~/models'
import { bewizrLearningTracksStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  Chip,
  HBox,
  Hero,
  Label,
  Markdown,
  Panel,
  PushButton,
  TextBlock,
  VBox,
} from '~/ui/components'
import Timeline, { TimelineItem } from '~/ui/components/Timeline'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { BeWizrSection } from '../bewizr'
import BeWizrCourseTile from '../bewizr-courses/BeWizrCourseTile'
import BeWizrMeetingTile from '../bewizr-meetings/BeWizrMeetingTile'

export interface Props {
  learningTrack: BeWizrLearningTrack
}

const BeWizrLearningTrackView = observer('BeWizrLearningTrackView', (props: Props) => {

  const [t, i18next] = useTranslation('bewizr-learning-tracks')

  const {learningTrack} = props

  const {colors} = useStyling()

  const description = React.useMemo(() => {
    if (learningTrack.descriptionHtml == null || learningTrack.descriptionHtml.length === 0) { return null }
    // A little bit ridiculous, but this way we can use the styling from the <Markdown/> component
    return NodeHtmlMarkdown.translate(learningTrack.descriptionHtml)
  }, [learningTrack.descriptionHtml])

  const image: RemoteImage | undefined = learningTrack.links.image == null ? undefined : {
    type: 'remote',
    url:   learningTrack.links.image,
  }

  //------
  // Enrollment

  const document = bewizrLearningTracksStore.learningTracks.document(learningTrack.id)

  const enrolled = document.enrolled
  const [enrolling, setEnrolling] = React.useState<boolean>(false)

  const timer = useTimer()

  const enroll = React.useCallback(async () => {
    if (enrolling) { return }

    setEnrolling(true)

    const result = await timer.await(document.enroll())
    if (result?.status === 200) {
      await document.fetch()
      Toast.show({
        type:   'success',
        title:  t('enroll.notification.title', {learningTrack}),
        detail: t('enroll.notification.detail', {learningTrack}),
        lifetime: 20_000,
      })
    }

    setEnrolling(false)
  }, [document, timer, enrolling, t, learningTrack])

  //------
  // Rendering

  const $ = useStyles()

  const renderItem = React.useCallback((item: BeWizrLearningTrackItem) => {
    if (item.type === 'course') {
      return (
        <BeWizrCourseTile
          title={item.title}
          slug={item.slug}
          progress={item.progress}
          image={item.links.image}
          locked={!enrolled || item.isLocked}
          horizontal
        />
      )
    } else {
      return (
        <BeWizrMeetingTile
          meetingID={item.meetingId}
          occurrenceID={item.id}
          title={item.title}
          image={item.links.image}
          startDate={item.startDate}
          endDate={item.endDate}
          locked={!enrolled || item.isLocked}
          horizontal
        />
      )
    }
  }, [enrolled])

  const items = React.useMemo(() => {
    const now = DateTime.now()

    const items: TimelineItem[] = [{
      caption:   t('start'),
      detail:    enrolled ? learningTrack.startDate?.toFormat('d-M-yyyy', {locale: i18next.language}) : null,
      completed: enrolled && (learningTrack.startDate == null || learningTrack.startDate <= now),
    }]

    learningTrack.items?.forEach(item => {
      items.push({
        render:    () => renderItem(item),
        completed: item.type === 'course' ? item.progress === 100 : item.endDate != null && item.endDate < now,
      })
    })

    items.push({
      icon:      'finish-flag',
      detail:    learningTrack.endDate?.toFormat('d-M-yyyy', {locale: i18next.language}),
      completed: every(items, it => it.completed),
    })

    return items
  }, [learningTrack.startDate, learningTrack.endDate, learningTrack.items, renderItem, t, i18next, enrolled])

  function render() {
    return (
      <VBox classNames={$.BeWizrLearningTrackView}>
        <Hero
          title={renderTitle()}
          image={image}
          backgroundImage={image}
          children={renderDescription()}
        />
        <VBox align='center' padding={layout.padding.m}>
          {renderContent()}
        </VBox>
      </VBox>
    )
  }

  function renderTitle() {
    return (
      <VBox gap={layout.padding.inline.s}>
        <Label h1 truncate={false}>{learningTrack.title}</Label>
        {renderMetaData()}
      </VBox>
    )
  }

  function renderDescription() {
    if (description == null) { return null }

    return (
      <Markdown>
        {description}
      </Markdown>
    )
  }

  function renderMetaData() {
    return (
      <HBox>
        <Chip
          icon='unordered-list'
          backgroundColor={colors.fg.highlight}
          children={t('items.count', {count: learningTrack.itemCount})}
        />
      </HBox>
    )
  }

  function renderContent() {
    return (
      <VBox classNames={$.content} gap={layout.padding.xl} paddingVertical={layout.padding.l}>
        {!enrolled && (
          <BeWizrSection title={t('enroll.caption')}>
            {renderEnroll()}
          </BeWizrSection>
        )}
        <BeWizrSection title={t('items.title')}>
          <Timeline items={items} large/>
        </BeWizrSection>
      </VBox>
    )
  }

  function renderEnroll() {
    if (enrolled) { return null }

    return (
      <Panel padding={layout.padding.inline.l} gap={layout.padding.m}>
        <TextBlock small>
          {t('enroll.instruction')}
        </TextBlock>
        <PushButton
          caption={t('enroll.caption')}
          working={enrolling}
          onTap={enroll}
        />
      </Panel>

    )
  }

  return render()

})

export default BeWizrLearningTrackView

const useStyles = createUseStyles({
  BeWizrLearningTrackView: {
  },

  content: {
    width:    layout.contentWidth,
    maxWidth: '100%',
  },
})