import { action, makeObservable, observable } from 'mobx'
import { Database } from 'mobx-document'
import authenticationStore from './authenticationStore'
import { ChallengeDocument, ChallengeScope, ChallengesEndpoint } from './challenges'
import { register } from './support'

export class ChallengesStore {

  constructor() {
    makeObservable(this)
  }

  public readonly challenges = new Database<ChallengeDocument>({
    getID:         challenge       => challenge.id,
    getDocument:   challenge       => new ChallengeDocument(challenge.id, {initialData: challenge}),
    emptyDocument: id              => new ChallengeDocument(id),
  })

  public readonly allChallenges       = new ChallengesEndpoint('all', this.challenges)
  public readonly openChallenges      = new ChallengesEndpoint('open', this.challenges)
  public readonly completedChallenges = new ChallengesEndpoint('completed', this.challenges)
  public readonly resultsChallenges   = new ChallengesEndpoint('results', this.challenges)

  public endpointForScope(scope: ChallengeScope = 'all') {
    return new ChallengesEndpoint(scope, this.challenges)
  }

  //------
  // Answers

  @observable
  public answers: Map<string, Record<string, any>> = new Map()

  public getCurrentAnswer(challengeID: string, taskUUID: string) {
    const currentAnswers = this.answers.get(challengeID)
    const currentAnswer  = currentAnswers?.[taskUUID]
    if (currentAnswer !== undefined) { return currentAnswer }

    const document = this.challenges.document(challengeID)
    return document.answers[taskUUID]?.answer ?? null
  }

  public getAllCurrentAnswers(challengeID: string) {
    const document  = this.challenges.document(challengeID)
    const challenge = document.data
    if (challenge == null) { return {} }

    return challenge.tasks.reduce((answers, task) => ({
      ...answers,
      [task.uuid]: this.getCurrentAnswer(challengeID, task.uuid),
    }), {})
  }

  @action
  public setCurrentAnswer(challengeID: string, taskUUID: string, answer: any) {
    const firstAnswer    = !this.answers.has(challengeID)
    const currentAnswers = this.answers.get(challengeID) ?? {}

    this.answers.set(challengeID, {
      ...currentAnswers,
      [taskUUID]: answer,
    })

    if (firstAnswer) {
      this.startChallenge(challengeID)
    }
  }

  //------
  // Task completion

  @observable
  private completedTasks = new Map<string, boolean>()

  public taskCompleted(challengeID: string, taskUUID: string) {
    const document = this.challenges.document(challengeID)
    if (document?.state?.completedAt != null) { return true }

    if (this.completedTasks.get(`${challengeID}::${taskUUID}`) === false) {
      return false
    } else {
      return this.getCurrentAnswer(challengeID, taskUUID) != null
    }
  }

  public completeTask(challengeID: string, taskUUID: string) {
    this.completedTasks.set(`${challengeID}::${taskUUID}`, true)
  }

  //------
  // State

  @action
  public startChallenge(challengeID: string) {
    const document = this.challenges.document(challengeID)
    for (const task of document.data?.tasks ?? []) {
      this.completedTasks.set(`${challengeID}::${task.uuid}`, false)
    }

    return document.startChallenge()
  }

  @action
  public completeChallenge(challengeID: string) {
    const document = this.challenges.document(challengeID)
    const answers  = this.getAllCurrentAnswers(challengeID)
    return document.completeChallengeWithAnswers(answers)
      ?.then(success => this.onCompleteChallengeComplete(challengeID, success))
  }

  @action
  private onCompleteChallengeComplete = (challengeID: string, success: boolean) => {
    if (success) {
      this.answers.delete(challengeID)
    }

    this.openChallenges.fetch({})
    this.completedChallenges.fetch({})

    return success
  }

  //------
  // Log out & persistence

  public init() {
    authenticationStore.on('logout', this.onLogOut)
  }

  public persistenceKey = 'challenges'

  public get persistedState() {
    return {
      answers:        [...this.answers],
      completedTasks: [...this.completedTasks],
    }
  }

  public rehydrate(raw: any) {
    this.answers = new Map(raw.answers ?? [])
    this.completedTasks = new Map(raw.completedTasks ?? [])
  }

  private onLogOut = () => {
    this.challenges.clear()
    this.answers.clear()
    this.allChallenges.clear()
    this.openChallenges.clear()
    this.completedChallenges.clear()
    this.resultsChallenges.clear()
  }

}

export default register(new ChallengesStore())