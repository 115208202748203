import React from 'react'
import { useTranslation } from 'react-i18next'
import clipboard from 'rich-clipboard'
import { ClipboardType, RichLinkClipboardItem } from '~/clipboard'
import { Link } from '~/models'
import { ClearButton, HBox, TextField, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useChat } from './ChatContext'

export interface Props {
  requestUpload?: () => any
  enabled?:       boolean
}

const ChatInputBar = React.memo((props: Props) => {

  const {requestUpload, enabled: props_enabled} = props

  const {service: {currentChat}} = useChat()
  const enabled = props_enabled && currentChat != null

  const [t] = useTranslation('chat')

  const [text, setText] = React.useState<string>('')
  const [link, setLink] = React.useState<Link | null>()

  //------
  // Callbacks

  const sendMessage = React.useCallback(async () => {
    const clean = text.trim()
    if (clean === '') { return }

    setText('')
    setLink(null)

    const hasLink = link != null && text.includes(link.href)

    currentChat?.sendMessage({
      type: 'text',
      text: hasLink ? text.replace(link.href, '') : text,
      link: hasLink ? link : undefined,
    })
  }, [text, link, currentChat])

  const handlePaste = React.useCallback((event: React.ClipboardEvent) => {
    const richLink = clipboard.getData<RichLinkClipboardItem>(ClipboardType.RICH_LINK, false)?.data
    if (richLink == null) { return }

    const newText = t('input_bar.paste.rich_link', {item: richLink})
    setText(text.length === 0 ? newText : `${text} ${newText}`)
    setLink(richLink.link)

    event.preventDefault()
    return
  }, [t, text])

  //------
  // Typing

  React.useEffect(() => {
    if (currentChat == null) { return }
    if (text.trim() === '') {
      currentChat.stopTyping()
    } else {
      currentChat.startTyping()
    }
  }, [currentChat, text])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox gap={layout.padding.inline.m} classNames={$.chatInputBar}>
        <VBox flex>
          <TextField
            value={text}
            onChange={setText}
            onCommit={sendMessage}
            placeholder={t('input_bar.placeholder')}
            accessoryRight={renderAttachmentButton()}
            enabled={enabled}
            inputStyle='dark'
            inputAttributes={{
              onPaste: handlePaste,
            }}
          />
        </VBox>
      </HBox>
    )
  }

  function renderAttachmentButton() {
    if (requestUpload == null) { return null }

    return (
      <ClearButton
        icon='paperclip'
        onTap={requestUpload}
      />
    )
  }

  return render()

})

export default ChatInputBar

const useStyles = createUseStyles({
  chatInputBar: {
    padding:    layout.padding.inline.l,
    paddingTop: 0,
  },
})
