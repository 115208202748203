import React from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { DateTime } from 'luxon'
import { BeWizrActivity } from '~/models'
import { bewizrAgendaStore, clockStore } from '~/stores'
import { memo, observer } from '~/ui/component'
import { Empty, Label, List, ListBar, ListSection, SVG, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { groupItemsByDay } from './util'

export interface Props {
  items: BeWizrActivity[]
}

const BeWizrAgendaView = observer('BeWizrAgendaView', (props: Props) => {

  const {items} = props

  const [t] = useTranslation('bewizr-agenda')

  const currentTime = React.useMemo(
    () => clockStore.serverTime(DateTime.now()) ?? DateTime.now(),
    [],
  )

  const formatDate = React.useCallback((date: DateTime) => {
    const format = date.hasSame(currentTime, 'year') ? 'd LLLL' : 'd LLLL yyyy'
    return date.toFormat(format, {locale: i18next.language})
  }, [currentTime])

  const itemsByDay = React.useMemo((): ListSection<BeWizrActivity>[] => {
    const grouped = groupItemsByDay(items)
    return Array.from(grouped).map(([date, items]) => ({
      name:  formatDate(date),
      items: items,
    }))
  }, [items, formatDate])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <List
        flex={false}
        data={itemsByDay}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        EmptyComponent={renderEmpty}
        itemGap={layout.padding.inline.s}
      />
    )
  }

  const renderSectionHeader = React.useCallback((section: ListSection<BeWizrActivity>, index: number) => (
    <VBox classNames={[$.date, {first: index === 0}]}>
      <Label h3>{section.name}</Label>
    </VBox>
  ), [$.date])

  const keyExtractor = React.useCallback(
    (item: BeWizrActivity) => item.id,
    [],
  )

  const renderItem = React.useCallback((item: BeWizrActivity) => (
    <BeWizrActivityListItem item={item} />
  ), [])

  const renderEmpty = React.useCallback(() => (
    <Empty
      {...t('empty')}
    />
  ), [t])

  return render()

})

export default BeWizrAgendaView

const useStyles = createUseStyles({
  date: {
    paddingBottom: layout.padding.inline.l,
    '&:not(.first)': {
      paddingTop: layout.padding.inline.xl,
    },
  },
})

interface BeWizrActivityListItemProps {
  item: BeWizrActivity
}

const BeWizrActivityListItem = memo('BeWizrActivityListItem', (props: BeWizrActivityListItemProps) => {

  const {item} = props

  //------
  // Rendering

  function render() {
    return (
      <ListBar
        image={renderImage()}
        caption={item.title}
        detail={renderTime()}
        accessory={<SVG name='chevron-right' size={layout.icon.m} dimmer/>}
        href={bewizrAgendaStore.hrefForActivity(item)}
      />
    )
  }

  function renderImage() {
    return (
      <SVG
        name='meeting'
        size={layout.icon.l}
        primary
      />
    )
  }

  function renderTime() {
    return `${item.startDate.toFormat('HH:mm')} - ${item.endDate.toFormat('HH:mm')}`
  }

  return render()

})