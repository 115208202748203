import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { bewizrLibraryStore } from '~/stores'
import { observer } from '~/ui/component'
import { Spinner } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import BeWizrLibraryView from './BeWizrLibraryView'

export type Props = RouteComponentProps

const BeWizrLibraryScreen = observer('BeWizrLibraryScreen', (props: Props) => {

  const [t] = useTranslation('bewizr-library')

  const endpoint    = bewizrLibraryStore.allItems
  const fetchStatus = endpoint.fetchStatus

  //------
  // Rendering

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={t('title')}
          ActionsComponent={renderSpinner}
        />
        <BeWizrLibraryView
          endpoint={endpoint}
        />
      </>
    )
  }

  function renderSpinner() {
    if (fetchStatus !== 'fetching') { return null }
    return (
      <Spinner dim size={12}/>
    )
  }

  return render()

})

export default BeWizrLibraryScreen