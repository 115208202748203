import { makeAutoObservable } from 'mobx'
import { Database } from 'mobx-document'
import { BeWizrMeetingDocument } from './bewizr-meetings'
import { register } from './support'

export class BeWizrMeetingsStore {

  public constructor() {
    makeAutoObservable(this)
  }

  public readonly meetings = new Database<BeWizrMeetingDocument>({
    getID:         meeting => meeting.id,
    getDocument:   meeting => new BeWizrMeetingDocument(meeting.id, {initialData: meeting}),
    emptyDocument: id      => new BeWizrMeetingDocument(id),
  })

}

export default register(new BeWizrMeetingsStore())