import { makeAutoObservable } from 'mobx'
import { Database } from 'mobx-document'
import {
  BeWizrLibraryCategoriesEndpoint,
  BeWizrLibraryCategoryDocument,
  BeWizrLibraryItemDocument,
  BeWizrLibraryItemsEndpoint,
  BeWizrLibraryTopicDocument,
  BeWizrLibraryTopicsEndpoint,
} from './bewizr-library'
import { register } from './support'

export class BeWizrLibraryStore {

  constructor() {
    makeAutoObservable(this)
  }

  public readonly topics = new Database<BeWizrLibraryTopicDocument>({
    getID:         topic => topic.slug,
    getDocument:   topic => new BeWizrLibraryTopicDocument(topic.slug, {initialData: topic}),
    emptyDocument: slug    => new BeWizrLibraryTopicDocument(slug),
  })

  public readonly categories = new Database<BeWizrLibraryCategoryDocument>({
    getID:         category => category.slug,
    getDocument:   category => new BeWizrLibraryCategoryDocument(category.slug, {initialData: category}),
    emptyDocument: slug     => new BeWizrLibraryCategoryDocument(slug),
  })

  public readonly items = new Database<BeWizrLibraryItemDocument>({
    getID:         item => item.id,
    getDocument:   item => new BeWizrLibraryItemDocument(item.id, {initialData: item}),
    emptyDocument: id => new BeWizrLibraryItemDocument(id),
  })

  public readonly allTopics     = new BeWizrLibraryTopicsEndpoint(this.topics, {})
  public readonly allCategories = new BeWizrLibraryCategoriesEndpoint(this.categories, {})
  public readonly allItems      = new BeWizrLibraryItemsEndpoint(this.items, {})

}

export default register(new BeWizrLibraryStore())