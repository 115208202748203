import React from 'react'
import { useTranslation } from 'react-i18next'
import { bewizrCoursesStore } from '~/stores'
import { observer } from '~/ui/component'
import { Dimple, Label, Panel, PushButton, VBox } from '~/ui/components'
import { layout, useStyling } from '~/ui/styling'
import BeWizrCourseCertificate from './BeWizrCourseCertificate'
import BeWizrCourseProgressBar from './BeWizrCourseProgressBar'

export interface Props {
  slug: string
}

const BeWizrCourseProgressPanel = observer('BeWizrCourseProgressPanel', (props: Props) => {

  const {slug} = props

  const document = bewizrCoursesStore.courses.document(slug)
  const course   = document.data

  const [t] = useTranslation('bewizr-courses')

  const {colors} = useStyling()

  const started   = course?.progress != null
  const completed = course?.progress === 100

  const buttonCaption = !started ? t('start') : !completed ? t('continue') : t('view')
  const href          = course?.links.continue


  //------
  // Rendering

  function render() {
    return (
      <Panel gap={layout.padding.m} padding={padding} backgroundColor={colors.bg.alt}>
        <BeWizrCourseProgressBar progress={course?.progress ?? 0}/>
        <Dimple counterPadding={padding} horizontal/>
        {renderCertificate()}
        <PushButton
          href={href}
          caption={buttonCaption}
        />
      </Panel>
    )
  }

  function renderCertificate() {
    if (!course?.deliverCertificate) { return null }
    return (
      <>
        <VBox gap={layout.padding.inline.s}>
          <Label h3>{t('progress.certificate.title')}</Label>
          <BeWizrCourseCertificate course={course}/>
        </VBox>
        <Dimple counterPadding={padding} horizontal/>
      </>
    )
  }

  return render()

})

export default BeWizrCourseProgressPanel

const padding = layout.padding.m