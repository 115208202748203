import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { BeWizrMeetingOccurrence } from '~/models'
import { bewizrMeetingsStore } from '~/stores'
import { memo, observer } from '~/ui/component'
import { Chip, HBox, Label, List, ListBar, SVG } from '~/ui/components'
import { layout, useStyling } from '~/ui/styling'
import BeWizrMeetingOccurrenceDateTimeView from './BeWizrMeetingOccurrenceDateTimeView'

export interface Props {
  meetingID:            string
  selectedOccurrenceID: string | null

  requestSelect: (occurrence: BeWizrMeetingOccurrence) => any
  scope?:        'all' | 'upcoming' | 'past' | 'open'
}

const BeWizrMeetingOccurrencesList = observer('BeWizrMeetingOccurrencesList', (props: Props) => {

  const {requestSelect, selectedOccurrenceID, scope = 'upcoming'} = props

  const document = bewizrMeetingsStore.meetings.document(props.meetingID)
  const meeting  = document.data

  const filteredOccurrences = React.useMemo(() => {
    const now = DateTime.now()
    if (meeting == null) {
      return []
    } else if (scope === 'past') {
      return meeting.occurrences.filter(it => it.startDate < now)
    } else if (scope === 'upcoming') {
      return meeting.occurrences.filter(it => it.startDate > now || it.startDate.hasSame(now, 'day'))
    } else if (scope === 'open') {
      return meeting.occurrences.filter(it => it.startDate > now && (it.links.enroll != null || it.links.cancel))
    } else {
      return meeting.occurrences
    }
  }, [meeting, scope])

  //------
  // Rendering

  function render() {
    return (
      <List
        flex={false}
        data={filteredOccurrences}
        keyExtractor={keyExtractor}
        renderItem={renderOccurrence}
        itemGap={layout.padding.inline.s}
      />
    )
  }

  const keyExtractor = React.useCallback(
    (occurrence: BeWizrMeetingOccurrence) => occurrence.id,
    [],
  )

  const renderOccurrence = React.useCallback((occurrence: BeWizrMeetingOccurrence) => (
    <BeWizrMeetingOccurrencesListItem
      selected={occurrence.id === selectedOccurrenceID}
      requestSelect={requestSelect}
      occurrence={occurrence}
    />
  ), [selectedOccurrenceID, requestSelect])

  return render()

})

export default BeWizrMeetingOccurrencesList

interface BeWizrMeetingOccurrencesListItemProps {
  occurrence:     BeWizrMeetingOccurrence
  selected:      boolean
  requestSelect: (occurrence: BeWizrMeetingOccurrence) => any
}

const BeWizrMeetingOccurrencesListItem = memo('BeWizrMeetingOccurrencesListItem', (props: BeWizrMeetingOccurrencesListItemProps) => {

  const {occurrence, selected, requestSelect} = props

  const [t] = useTranslation('bewizr-meetings')

  const {colors} = useStyling()

  const select = React.useCallback(
    () => requestSelect(occurrence),
    [requestSelect, occurrence],
  )

  //------
  // Rendering

  function render() {
    return (
      <ListBar
        caption={renderDate()}
        detail={renderTimeAndLocation()}
        accessory={renderEnrolled()}
        onTap={select}
        selected={selected}
      />
    )
  }

  function renderDate() {
    return (
      <BeWizrMeetingOccurrenceDateTimeView
        occurrence={occurrence}
        timeFormat={false}
        dateFormat='d LLLL yyyy'
        small
        bold
      />
    )
  }

  function renderTimeAndLocation() {
    return (
      <HBox gap={layout.padding.inline.s} wrap>
        <HBox gap={layout.padding.inline.s}>
          <SVG name='clock' size={layout.icon.xs} dim/>
          <BeWizrMeetingOccurrenceDateTimeView
            occurrence={occurrence}
            dateFormat={false}
            tiny
            dim
          />
        </HBox>
        {occurrence.location != null && (
          <HBox gap={layout.padding.inline.s}>
            <SVG name='map-pin' size={layout.icon.xs} dim/>
            <Label tiny dim>
              {occurrence.location.title}
            </Label>
          </HBox>
        )}
      </HBox>

    )
  }

  function renderEnrolled() {
    if (!occurrence.isEnrolled) { return null }
    return (
      <Chip backgroundColor={colors.semantic.positive} icon='check' small>
        {t('enrollment.status.enrolled.short')}
      </Chip>
    )
  }

  return render()

})