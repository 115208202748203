import I18Next from 'i18next'
import { DateTime } from 'luxon'
import { Channel } from './Channel'
import { Link } from './Link'
import {
  ImageMessageTemplate,
  MessageFeedback,
  MessageMedia,
  MessageTemplate,
  NoticeMessageTemplate,
  TextMessageTemplate,
  VideoMessageTemplate,
  WidgetMessageTemplate,
} from './MessageTemplate'
import { Model } from './Model'
import { Participant } from './Participant'
import { Sender } from './Sender'
import { datetime } from './serialization'
import { Ref } from './types'

export class Message extends Model {
  public channel!:  Ref<Channel>
  public from!:     string
  public replyTo!:  Message | null
  public feedback!: MessageFeedback | null

  public status!: MessageStatus

  @datetime()
  public sentAt!: DateTime

  @datetime()
  public readAt!: DateTime | null

  public timestamp!:  number

  @datetime() public answeredAt!: DateTime | null

  public moderatedBy?: Ref<Participant> | null
  @datetime() public approvedAt?: DateTime | null
  @datetime() public redactedAt?: DateTime | null

  public stale!: boolean

  public feedbackExpiredAt(date: DateTime) {
    const timeLeft = this.feedbackTimeLeftAt(date)
    if (timeLeft == null) { return false }

    return timeLeft < 0
  }

  /**
   * Returns the number of seconds left to answer this message. If the message cannot be answered
   * at all, a value of `null` is returned.
   *
   * @param date The current date.
   */
  public feedbackTimeLeftAt(date: DateTime) {
    if (this.feedback == null) { return null }
    if (this.feedback.expiresAfter == null) { return null }

    const secondsSinceSent = (date.valueOf() - this.sentAt.valueOf()) / 1000
    return this.feedback.expiresAfter - secondsSinceSent
  }

  public showFeedbackAt(date: DateTime) {
    if (this.feedback == null) { return false }
    if (this.answeredAt != null) { return false }
    if (this.stale) { return false }

    return !this.feedbackExpiredAt(date)
  }

  //------
  // Types & content

  public type!: MessageTemplate['type']

  public text?:       string
  public sticky?:     boolean
  public link?:       Link | null
  public image?:      MessageMedia
  public video?:      MessageMedia
  public zoom?:       number
  public placeID?:    string | null
  public caption?:    string | null
  public widget?:     string
  public params?:     AnyObject

  public get asText() {
    return this as TextMessageTemplate
  }

  public get asNotice() {
    return this as NoticeMessageTemplate
  }

  public get asImage() {
    return this as ImageMessageTemplate
  }

  public get asVideo() {
    return this as VideoMessageTemplate
  }

  public get asWidget() {
    return this as WidgetMessageTemplate
  }

  //------
  // Derived

  public notificationText(scope: 'received' | 'sent' | 'received-short', sender: Sender) {
    return I18Next.t(`chat:messages.${scope}.${this.type}`, {message: this, sender})
  }

  //------
  // Status

  public updateStatus(update: MessageStatusUpdate) {
    const nextMessage = this.copy() as Message
    nextMessage.status = update.status
    if (update.answeredAt != null) {
      nextMessage.answeredAt = update.answeredAt
    }

    return nextMessage
  }

  //------
  // Moderation

  public approve(moderatorID: string) {
    const nextMessage = this.copy()
    nextMessage.moderatedBy = moderatorID
    nextMessage.approvedAt = new Date()
    nextMessage.redactedAt = null
    return nextMessage
  }

  public redact(moderatorID: string) {
    const nextMessage = this.copy()
    nextMessage.moderatedBy = moderatorID
    nextMessage.approvedAt = null
    nextMessage.redactedAt = new Date()
    return nextMessage
  }

  public undoModeration() {
    const nextMessage = this.copy()
    nextMessage.moderatedBy = null
    nextMessage.approvedAt = null
    nextMessage.redactedAt = null
    return nextMessage
  }

  //------
  // Answered

  public get isAnswered() {
    return this.answeredAt != null
  }

  public markAsAnswered(date: DateTime = DateTime.local()) {
    const nextMessage = this.copy()
    nextMessage.answeredAt = date
    return nextMessage
  }

}

//------
// Status

export interface MessageStatusUpdate {
  status:      MessageStatus
  readAt?:     DateTime
  answeredAt?: DateTime
}

export type MessageStatus = 'pending' | 'unread' | 'read-by-some' | 'read' | 'stale' | 'invalid' | 'error' | 'redacted'