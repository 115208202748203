import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bewizrLearningTracksStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Scroller } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import BeWizrLearningTrackView from './BeWizrLearningTrackView'

export interface BeWizrLearningTrackParams {
  id: string
}

export type Props = RouteComponentProps<BeWizrLearningTrackParams>

const BeWizrLearningTrackScreen = observer('BeWizrLearningTrackScreen', (props: Props) => {

  const {id} = props.match.params

  const document      = bewizrLearningTracksStore.learningTracks.document(id)
  const learningTrack = document.data
  const fetchStatus   = document.fetchStatus

  React.useEffect(() => {
    document?.fetch()
  }, [document])

  //------
  // Rendering

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={learningTrack?.title ?? null}
          fetchStatus={fetchStatus}
        />
        {renderContent()}
      </>
    )
  }

  function renderContent() {
    if (learningTrack == null || fetchStatus !== 'done') {
      return renderEmpty()
    }

    return (
      <Scroller>
        <BeWizrLearningTrackView
          learningTrack={learningTrack}
        />
      </Scroller>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        flex
        status={fetchStatus}
      />
    )
  }

  return render()

})

export default BeWizrLearningTrackScreen