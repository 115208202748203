import { CollectionFetchOptions, Database, Endpoint } from 'mobx-document'
import socket from 'socket.io-react'
import { Question } from '~/models'
import QAndADocument from './QAndADocument'
import QuestionDocument from './QuestionDocument'
import { QAndAMeta, QuestionsMeta, QuestionsParams } from './types'

export default class QuestionsEndpoint extends Endpoint<QuestionDocument, QuestionsParams, QuestionsMeta> {

  constructor(
    database: Database<QuestionDocument>,
    private readonly qAndADocument: QAndADocument,
  ) {
    super(database, {search: null, sort: 'date'}, {})
  }

  public replaceFromMeta(meta: QAndAMeta) {
    const questions  = meta.questions.data.map(raw => Question.deserialize(raw))
    const total      = meta.questions.total
    const nextOffset = meta.questions.nextOffset

    this.replace(questions, {total, nextOffset})
    this.fetchStatus = 'done'
  }

  public async performFetch(params: QuestionsParams, options: CollectionFetchOptions) {
    const response = await socket.fetch('q-and-as:questions:list', this.qAndADocument.id, {
      search: params.search,
      sort:   params.sort,
      ...options,
    })

    if (!response.ok) {
      return response
    }

    const pack = response.body
    const data = pack.data.map((raw: any) => Question.deserialize(raw))
    const meta = pack.meta

    return {data, meta}
  }

  public async fetchMore() {
    if (this.fetchStatus !== 'done') { return }
    if (this.meta?.nextOffset == null) { return }

    await this.fetch({
      offset: this.meta.nextOffset,
      append: true,
    })
  }
}