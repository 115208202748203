import { CollectionFetchOptions, Endpoint } from 'mobx-document'
import socket from 'socket.io-react'
import { Notification } from '~/models'
import NotificationDocument from './NotificationDocument'

export default class NotificationsEndpoint extends Endpoint<NotificationDocument, NotificationParams> {

  private nextPageToken: string | null = null

  public async fetchMore() {
    if (this.nextPageToken == null) { return }

    return await this.fetch({
      extraParams: {
        pageToken: this.nextPageToken,
      },
      append: true,
    })
  }

  public async performFetch(params: NotificationParams, options: CollectionFetchOptions): Promise<any> {
    const response = await socket.fetch('notifications:list', {
      ...params,
      limit: options.limit,
    })

    if (response.ok) {
      const {
        notifications,
        ...meta
      } = response.body

      return {
        data: notifications.map((it: any) => Notification.deserialize(it)),
        meta: meta,
      }
    } else {
      return {
        error: response.error,
      }
    }
  }

}

export interface NotificationParams {
  pageToken?: string | null
}