import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { Markdown, VBox } from '~/ui/components'
import { createUseStyles, fonts, layout } from '~/ui/styling'

export interface Props {}

const AuthFooter = memo('AuthFooter', (props: Props) => {

  const [t] = useTranslation('auth')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.AuthFooter} align='center'>
        <Markdown>
          {t('footer')}
        </Markdown>
      </VBox>
    )
  }

  return render()

})

export default AuthFooter

const useStyles = createUseStyles(theme => ({
  AuthFooter: {
    backgroundColor: theme.bg.normal.alpha(0.75),
    ...layout.responsive(size => ({
      paddingTop:    layout.padding.m[size],
      paddingBottom: layout.padding.m[size],
    })),

    '& > div': {
      ...fonts.responsiveFontStyle(theme.fonts.tiny),
      color: theme.fg.dim,
      textAlign: 'center',
    },
  },
}))