import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { BeWizrTile } from '../bewizr'
import BeWizrCourseProgressBar from './BeWizrCourseProgressBar'

export interface Props {
  title:       string
  slug:        string
  image:       string | null
  progress:    number | null | false
  locked?:     boolean
  horizontal?: boolean
}

const BeWizrCourseTile = memo('BeWizrCourseTile', (props: Props) => {

  const {title, slug, image, progress, horizontal, locked} = props

  const [t] = useTranslation('bewizr-courses')

  //------
  // Rendering

  function render() {
    return (
      <BeWizrTile
        icon='course'
        title={title}
        href={`/bewizr-courses/-/${slug}`}
        image={image}
        children={renderProgress()}
        buttonCaption={t('view')}
        horizontal={horizontal}
        locked={locked}
      />
    )
  }

  function renderProgress() {
    if (progress === false) { return null }
    return (
      <BeWizrCourseProgressBar
        progress={progress ?? 0}
      />
    )
  }

  return render()

})

export default BeWizrCourseTile