import { makeAutoObservable } from 'mobx'
import { Database } from 'mobx-document'
import authenticationStore from './authenticationStore'
import { BeWizrLearningTrackDocument, BeWizrLearningTracksEndpoint } from './bewizr-learning-tracks'
import { register } from './support'

export class BeWizrLearningTracksStore {

  public constructor() {
    makeAutoObservable(this)
  }

  public init() {
    authenticationStore.on('logout', this.onLogOut)
  }

  public readonly learningTracks = new Database<BeWizrLearningTrackDocument>({
    getID:         track => track.id,
    getDocument:   track => new BeWizrLearningTrackDocument(track.id, {initialData: track}),
    emptyDocument: id    => new BeWizrLearningTrackDocument(id),
  })

  public readonly myLearningTracks = new BeWizrLearningTracksEndpoint(this.learningTracks, {})

  private onLogOut = () => {
    this.learningTracks.clear()
  }

}

export default register(new BeWizrLearningTracksStore())