import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { omit } from 'lodash'
import { BeWizrLibraryItemType } from '~/models'
import {
  BeWirzLibraryItemsParams,
  BeWizrLibraryCategoriesEndpoint,
  BeWizrLibraryItemsEndpoint,
  BeWizrLibraryTopicsEndpoint,
} from '~/stores'
import { observer } from '~/ui/component'
import { screenMinWidths } from '~/ui/styling/layout'
import { FilterFieldSet, FiltersForm } from '../filters'
import { Props as FilterFormProps } from '../filters/FiltersForm'

export interface Props extends Omit<FilterFormProps, 'onCommit' | 'onReset' | 'children'> {
  endpoint:           BeWizrLibraryItemsEndpoint
  topicsEndpoint:     BeWizrLibraryTopicsEndpoint | null
  categoriesEndpoint: BeWizrLibraryCategoriesEndpoint | null
}

const BeWizrLibraryFilters = observer('BeWizrLibraryFilters', (props: Props) => {

  const {endpoint, topicsEndpoint, categoriesEndpoint, ...rest} = props

  const [t] = useTranslation('bewizr-library')

  const topics     = topicsEndpoint?.data ?? []
  const categories = categoriesEndpoint?.data ?? []

  React.useEffect(() => {
    topicsEndpoint?.fetch()
  }, [topicsEndpoint])

  React.useEffect(() => {
    categoriesEndpoint?.fetch()
  }, [categoriesEndpoint])

  const filter = React.useCallback((params: Partial<BeWirzLibraryItemsParams>) => {
    if (params.category != null && (topicsEndpoint != null && params.topic == null)) {
      endpoint.resetAndFetch(omit(params, 'category'))
    } else {
      endpoint.resetAndFetch(params)
    }
  }, [endpoint, topicsEndpoint])

  const reset = React.useCallback(() => {
    endpoint.resetAndFetch()
  }, [endpoint])

  const count = React.useCallback((params: Record<string, string | string []>) => {
    let count = Object.keys(params).length
    if (params.category != null && (topicsEndpoint != null && params.topic == null)) {
      count = count - 1
    }
    if (params.query != null) {
      count = count - 1
    }
    return count
  }, [topicsEndpoint])

  const collapsed = !useMediaQuery({minWidth: screenMinWidths.tablet})

  //------
  // Rendering

  function render() {
    return (
      <FiltersForm
        onCommit={filter}
        onReset={reset}
        countFn={count}
        collapsed={collapsed}
        {...rest}
      >
        {renderType()}
        {renderTopic()}
        {renderCategory()}
      </FiltersForm>
    )
  }

  function renderType() {
    return (
      <FilterFieldSet
        name='type'
        choices={BeWizrLibraryItemType.all}
        caption={t('item-types.caption')}
        imageForChoice={BeWizrLibraryItemType.iconForType}
        captionForChoice={captionForType}
        valueForChoice={type => type}
        multi={false}
      />
    )
  }

  function renderTopic() {
    if (topics.length === 0) { return null }

    return (
      <FilterFieldSet
        name='topic'
        choices={topics}
        caption={t('topics.title')}
        imageForChoice={topic => topic.links.image == null ? 'archive' : ({
          type: 'remote',
          url:  topic.links.image,
        })}
        captionForChoice={topic => topic.title}
        valueForChoice={topic => topic.id}
        multi={false}
      />
    )
  }

  function renderCategory() {
    if (categories.length === 0) { return null }

    return (
      <FilterFieldSet
        name='category'
        choices={categories}
        caption={t('categories.title')}
        imageForChoice={() => 'folder'}
        captionForChoice={category => category.title}
        valueForChoice={category => category.id}
        multi={false}
      />
    )
  }

  const captionForType = React.useCallback((type: BeWizrLibraryItemType) => {
    return t(`item-types.${type}`)
  }, [t])

  return render()

})

export default BeWizrLibraryFilters
