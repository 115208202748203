import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { Chip } from '~/ui/components'
import { colors } from '~/ui/styling'
import { BeWizrTile } from '../bewizr'

export interface Props {
  id:           string
  title:        string
  itemCount?:   number
  image:        string | null
  horizontal?:  boolean
}

const BeWizrLearningTrackTile = memo('BeWizrLearningTrackTile', (props: Props) => {

  const {title, id, image, itemCount, horizontal} = props

  const [t] = useTranslation('bewizr-learning-tracks')

  //------
  // Rendering

  function render() {
    return (
      <BeWizrTile
        title={title}
        detail={renderDetail()}
        icon={itemCount == null ? 'unordered-list' : undefined}
        href={`//bewizr-learning-track/${id}`}
        buttonCaption={t('view')}
        image={image}
        horizontal={horizontal}
      />
    )
  }

  function renderDetail() {
    if (itemCount == null) { return null }
    return (
      <Chip
        backgroundColor={colors.white}
        small
        icon='unordered-list'
        children={t('items.count', {count: itemCount})}
      />
    )
  }

  return render()

})

export default BeWizrLearningTrackTile