import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { combinedFetchStatus } from 'mobx-document'
import { Question } from '~/models'
import { qAndAsStore } from '~/stores'
import { observer } from '~/ui/component'
import { Dimple, EmptyOrFetching, List, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { AppLayoutConfig } from '~/ui/layouts'
import { createUseStyles, layout } from '~/ui/styling'
import QAndAHeader from './QAndAHeader'
import QuestionForm from './QuestionForm'
import QuestionListItem from './QuestionListItem'

export interface QAndAParams {
  id: string
}

export type Props = RouteComponentProps<QAndAParams>

const QAndAScreen = observer('QAndAScreen', (props: Props) => {

  const {id} = props.match.params

  const document    = qAndAsStore.qAndAs.document(id)
  const fetchStatus = combinedFetchStatus(document.fetchStatus, document.questionsEndpoint.fetchStatus)
  const qAndA       = document.data

  const fetch = React.useCallback(() => {
    if (document.fetchStatus === 'done') {
      document.questionsEndpoint.fetch()
    } else {
      document.fetch()
    }
  }, [document])

  const fetchMore = React.useCallback(() => {
    document.questionsEndpoint.fetchMore()
  }, [document.questionsEndpoint])

  React.useEffect(fetch, [fetch])

  const [questionFormOpen, openQuestionForm, closeQuestionForm] = useBoolean()
  const [titleSuggestion, setTitleSuggestion] = React.useState<string>('')

  const addQuestion = React.useCallback((title: string) => {
    setTitleSuggestion(title)
    openQuestionForm()
  }, [openQuestionForm])

  const clearSearch = React.useCallback(() => {
    document.questionsEndpoint.setParams({search: null})
  }, [document.questionsEndpoint])

  const [t] = useTranslation('q-and-as')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={qAndA?.name ?? null}
          fetchStatus={fetchStatus}
        />

        {renderContent()}

        {qAndA != null && (
          <QuestionForm
            open={questionFormOpen}
            requestClose={closeQuestionForm}
            qAndA={qAndA}
            titleSuggestion={titleSuggestion}
            afterSubmit={clearSearch}
          />
        )}
      </>
    )
  }

  function renderContent() {
    if (qAndA == null) {
      return renderEmpty()
    }
    else {
      return renderBody()
    }
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        {...t('not_found')}
        status={fetchStatus}
        flex
      />
    )
  }

  function renderBody() {
    return (
      <VBox flex gap={layout.padding.inline.l}>
        <QAndAHeader
          document={document}
          requestAdd={addQuestion}
        />
        <Dimple horizontal/>
        <List
          data={document.questionsEndpoint.data}
          keyExtractor={keyExtractor}
          renderItem={renderQuestion}
          EmptyComponent={renderNoQuestions}
          itemGap={layout.padding.inline.m}
          contentClassNames={$.questionListContent}
          onEndReached={fetchMore}
          scrollable
        />
      </VBox>
    )
  }

  const keyExtractor = React.useCallback(
    (question: Question) => question.id,
    [],
  )

  const renderNoQuestions = React.useCallback(() => {
    return (
      <EmptyOrFetching
        {...t('no_questions')}
        status={fetchStatus}
        flex
      />
    )
  }, [fetchStatus, t])

  const renderQuestion = React.useCallback((question: Question) => {
    return (
      <QuestionListItem
        question={question}
      />
    )
  }, [])

  return render()

})

export default QAndAScreen

const useStyles = createUseStyles({
  questionListContent: {
    padding: [0, layout.padding.inline.l, layout.padding.inline.l],
  },
})