import { makeAutoObservable, when } from 'mobx'
import { Database } from 'mobx-document'
import authenticationStore from './authenticationStore'
import BeWizrCourseDocument from './bewizr-courses/BeWizrCourseDocument'
import BeWizrCourseItemDocument from './bewizr-courses/BeWizrCourseItemDocument'
import BeWizrCourseItemsEndpoint from './bewizr-courses/BeWizrCourseItemsEndpoint'
import BeWizrCoursesEndpoint from './bewizr-courses/BeWizrCoursesEndpoint'
import bewizrStore from './bewizrStore'
import searchStore from './searchStore'
import { register } from './support'

export class BeWizrCoursesStore {

  constructor() {
    makeAutoObservable(this)
  }

  public async init() {
    authenticationStore.on('logout', this.onLogOut)

    await when(() => bewizrStore.authenticated, () => {
      searchStore.registerEndpoint('bewizr-courses', new BeWizrCoursesEndpoint(this.courses, {}))
      searchStore.registerEndpoint('bewizr-course-items', new BeWizrCourseItemsEndpoint(this.courseItems, {}))
    })
  }

  public readonly courses = new Database<BeWizrCourseDocument>({
    getID:         course => course.slug,
    getDocument:   course => new BeWizrCourseDocument(course.slug, {initialData: course}),
    emptyDocument: slug   => new BeWizrCourseDocument(slug),
  })

  public readonly courseItems = new Database<BeWizrCourseItemDocument>({
    getID:         item => item.id,
    getDocument:   item => new BeWizrCourseItemDocument(item.id, {initialData: item}),
    emptyDocument: id   => new BeWizrCourseItemDocument(id),
  })

  public readonly myCourses = new BeWizrCoursesEndpoint(this.courses, {})

  private onLogOut = () => {
    this.courses.clear()
  }

}

export default register(new BeWizrCoursesStore())