import React from 'react'

export interface FiltersContext {
  value:    Record<string, string | string[]>
  assign:   (name: string, value: string | string[] | null) => void
  onCommit: (params: Record<string, string | string[]>) => void
  count:    number
}

const FiltersContext = React.createContext<FiltersContext>({
  value:    {},
  assign:   () => {},
  onCommit: () => {},
  count:    0,
})

export default FiltersContext