import React from 'react'
import { Helmet } from 'react-helmet'
import { NotificationHost } from 'react-notifications'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { FetchStatus } from 'mobx-document'
import { useHistory } from '~/history'
import { projectStore } from '~/stores'
import { ChatUri } from '~/stores/chat'
import ChatChatContainer from '~/ui/app/chat/ChatContainer'
import { ChatContainer, useChatService } from '~/ui/app/ChatContainer'
import ErrorBoundary from '~/ui/app/ErrorBoundary'
import { NavigationTabBar } from '~/ui/app/navigation'
import { Breadcrumb } from '~/ui/app/navigation/types'
import BrowserNotificationsBar from '~/ui/app/notifications/BrowserNotificationsBar'
import { memo, observer } from '~/ui/component'
import { HBox, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { screenMinWidths } from '~/ui/styling/layout'
import AppHeader from './AppHeader'
import AppLayoutContext, { AppLayoutConfig } from './AppLayoutContext'
import AppSideBar from './AppSideBar'
import AppSubHeader from './AppSubHeader'

export interface Props {
  children?: React.ReactNode
}

const AppLayout = observer('AppLayout', (props: Props) => {

  const {children} = props

  const history = useHistory()

  const [title, setTitle] = React.useState<string | null>(null)
  const [subHeaderVisible, showSubHeader, hideSubHeader] = useBoolean(true)
  const [breadcrumbs, setBreadcrumbs] = React.useState<Breadcrumb[]>([])
  const [fetchStatus, setFetchStatus] = React.useState<FetchStatus | null>(null)
  const [ActionsComponent, setActionsComponent] = React.useState<React.ComponentType<{}>>()

  const configure = React.useCallback((config: AppLayoutConfig) => {
    setTitle(config.title)
    setBreadcrumbs(config.breadcrumbs)
    setFetchStatus(config.fetchStatus)
    if (config.subHeaderVisible === false) {
      hideSubHeader()
    } else {
      showSubHeader()
    }
    // As the Actions Component itself may be a function, and the state hook will execute a function instead of holding on to it,
    // we need to wrap this in a function itself.
    setActionsComponent(() => config.ActionsComponent)
  }, [showSubHeader, hideSubHeader])

  const isDesktop    = useMediaQuery({minWidth: screenMinWidths.desktop})
  const isWidescreen = useMediaQuery({minWidth: screenMinWidths.widescreen})

  const tabs = projectStore.tabs
  const hasChatTab = React.useMemo(
    () => tabs.find(it => it.link.href.startsWith('//chat')),
    [tabs],
  )

  const collapsed = hasChatTab ? !isWidescreen : !isDesktop

  React.useEffect(() => {
    if (!collapsed && history.location.pathname.startsWith('/chat')) {
      history.replace(`/${history.location.hash}`)
    }
  }, [history, collapsed])

  const context = React.useMemo(() => ({
    title,
    subHeaderVisible,
    breadcrumbs,
    fetchStatus,
    ActionsComponent,
    collapsed,
    configure,
  }), [title, subHeaderVisible, breadcrumbs, fetchStatus, ActionsComponent, collapsed, configure])

  React.useEffect(() => {
    if (title == null || history.stack.length === 0) { return }
    history.stack[history.index].title = title
    history.save()
  }, [title, history])

  const project = projectStore.project

  const location = useLocation()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <AppLayoutContext.Provider value={context}>
        {title != null && <Helmet title={title}/>}
        <ChatContainer>
          <NotificationHost
            className={$.notificationHost}
            padding={layout.padding.inline.l}
          />
          {renderContent()}
        </ChatContainer>
      </AppLayoutContext.Provider>
    )
  }

  function renderContent() {
    return (
      <VBox classNames={$.content} flex='grow'>
        {project == null ? null : collapsed ? (
          <>
            <AppHeader/>
            {location.pathname !== '/chat' && subHeaderVisible  && <AppSubHeader/>}
            {location.pathname === '/chat' ? <ChatWrapper/> : (
              <ErrorBoundary>
                {children}
              </ErrorBoundary>
            )}
            <NavigationTabBar/>
          </>
        ) : (
          <HBox flex align='stretch'>
            <AppSideBar/>
            <VBox flex>
              {subHeaderVisible && <AppSubHeader/>}
              <ErrorBoundary>
                {children}
              </ErrorBoundary>
            </VBox>
            {hasChatTab && (
              <VBox classNames={$.chat}>
                <ChatWrapper/>
              </VBox>
            )}
          </HBox>
        )}
      </VBox>
    )
  }

  return render()

})

export default AppLayout

const ChatWrapper = memo('ChatWrapper', () => {

  const service = useChatService()
  const history = useHistory()
  const location = useLocation()

  React.useEffect(() => {
    const chatUri = location.hash?.slice(1) as ChatUri ?? null
    if (chatUri == null) { return }

    const [type, id] = chatUri.split(':')
    if (type !== 'private') { return }

    service?.ensurePrivateChat(id)
  }, [location.hash, service])

  React.useEffect(() => {
    if (service == null) { return }

    const chatUri = location.hash == null || location.hash === '' ? null : location.hash.slice(1) as ChatUri
    return service.syncWithAppNavigation(chatUri, uri => {
      history.replace(uri == null ? '#' : `#${uri}`)
    })
  }, [history, location.hash, service])

  //------
  // Rendering

  function render() {
    if (service == null) {return null}

    return (
      <ErrorBoundary>
        <ChatChatContainer
          service={service}
          subheader={<BrowserNotificationsBar/>}
        />
      </ErrorBoundary>
    )
  }

  return render()

})

export const chatPanelWidth = layout.columnWidth + layout.padding.inline.m

const useStyles = createUseStyles(theme => ({
  notificationHost: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: layout.z.notification,
    ...layout.breakpoint({minWidth: screenMinWidths.tablet})({
      left: 'auto',
      width: layout.columnWidth,
    }),
  },

  content: {
    position: 'relative',
  },

  chat: {
    width: layout.columnWidth,
    borderLeft: [2, 'solid', theme.border.dimmer],
  },

  BrowserNotificationsBar: {
    padding:    [layout.padding.inline.xs, layout.padding.inline.m],
    background: theme.bg.normal,
  },
}))