import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { combinedFetchStatus } from '~/lib/mobx-document'
import { bewizrLibraryStore } from '~/stores'
import { observer } from '~/ui/component'
import { Spinner } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import BeWizrLibraryView from './BeWizrLibraryView'

export interface BeWizrLibraryParams {
  slug: string
}

export type Props = RouteComponentProps<BeWizrLibraryParams>

const BeWizrLibraryTopicScreen = observer('BeWizrLibraryTopicScreen', (props: Props) => {

  const {slug} = props.match.params

  const document = bewizrLibraryStore.topics.document(slug)
  const topic    = document.data
  const endpoint = document.itemsEndpoint

  const fetchStatus = combinedFetchStatus(
    document.fetchStatus,
    endpoint.fetchStatus,
  )

  React.useEffect(() => {
    document.fetch()
  }, [document])

  //------
  // Rendering

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={topic?.title}
          fetchStatus={document.fetchStatus}
          ActionsComponent={renderSpinner}
        />
        <BeWizrLibraryView
          endpoint={endpoint}
          includeTopicFilter={false}
        />
      </>
    )
  }

  function renderSpinner() {
    if (fetchStatus !== 'fetching') { return null }
    return (
      <Spinner dim size={12}/>
    )
  }

  return render()

})

export default BeWizrLibraryTopicScreen