import { Document } from 'mobx-document'
import { AuthResetAccount } from './types'

export default class AuthResetAccountDocument extends Document<AuthResetAccount> {

  protected async performFetch() {
    return null
  }

}
