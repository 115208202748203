import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { BeWizrLibraryCategory, BeWizrLibraryItem } from '~/models'
import { BeWizrLibraryItemsEndpoint, bewizrLibraryStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, HBox, List, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { screenWidths } from '~/ui/styling/layout'
import BeWizrCourseTile from '../bewizr-courses/BeWizrCourseTile'
import BeWizrLearningTrackTile from '../bewizr-learning-tracks/BeWizrLearningTrackTile'
import BeWizrMeetingTile from '../bewizr-meetings/BeWizrMeetingTile'
import BeWizrLibraryFilters from './BeWizrLibraryFilters'

export interface Props {
  endpoint:            BeWizrLibraryItemsEndpoint
  categories?:         BeWizrLibraryCategory[]
  includeTopicFilter?: boolean
}

const BeWizrLibraryView = observer('BeWizrLibraryView', (props: Props) => {

  const {endpoint, includeTopicFilter = true} = props

  const [t] = useTranslation('bewizr-library')

  const fetchStatus = endpoint.fetchStatus
  const items       = endpoint.data

  const topicID        = endpoint.param('topic')
  const topicDocument  = topicID == null ? null : bewizrLibraryStore.topics.document(topicID)

  const topicsEndpoint = React.useMemo(
    () => !includeTopicFilter ? null : bewizrLibraryStore.allTopics,
    [includeTopicFilter],
  )

  const categoriesEndpoint = React.useMemo(
    () => topicDocument == null ? null : topicDocument.categoriesEndpoint,
    [topicDocument],
  )

  const fetchMore = React.useCallback(() => {
    endpoint?.fetchMore()
  }, [endpoint])

  const searchEnabled = endpoint.param('query')

  const isTablet = useMediaQuery({minWidth: screenWidths.tablet})

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return isTablet ? (
      <HBox align='stretch' flex>
        {renderContent()}
      </HBox>
    ) : (
      <VBox flex>
        {renderContent()}
      </VBox>
    )
  }

  function renderContent() {
     return (
      <>
        {renderFilters()}
        <VBox flex>
          <List
            data={items}
            onEndReached={fetchMore}
            renderItem={renderItem}
            EmptyComponent={renderEmpty}
            itemGap={layout.padding.s}
            contentPadding={layout.padding.m}
            scrollable
          />
        </VBox>
      </>
     )
  }

  const renderEmpty = React.useCallback(() => (
    <EmptyOrFetching
      {...t(`empty.${searchEnabled ? 'with_query' : 'without_query'}`)}
      status={fetchStatus}
      flex
    />
  ), [t, searchEnabled, fetchStatus])

  function renderFilters() {
    return (
      <VBox classNames={$.filters} gap={layout.padding.s} padding={layout.padding.m}>
        <BeWizrLibraryFilters
          endpoint={endpoint}
          topicsEndpoint={topicsEndpoint}
          categoriesEndpoint={categoriesEndpoint}
        />
      </VBox>
    )
  }

  function renderItem(item: BeWizrLibraryItem) {
    if (item.type === 'course') {
      return (
        <BeWizrCourseTile
          title={item.title}
          slug={item.slug}
          image={item.links.image}
          progress={false}
          horizontal
        />
      )
    } else if (item.type === 'learning_track') {
      return (
        <BeWizrLearningTrackTile
          title={item.title}
          image={item.links.image}
          id={item.id}
          horizontal
        />
      )
    } else if (item.type === 'meeting') {
      return (
        <BeWizrMeetingTile
          meetingID={item.id}
          title={item.title}
          image={item.links.image}
          horizontal
        />
      )
    } else {
      console.warn(`Learning activity "${item.type}" not supported`)
      return null
    }
  }

  return render()

})

export default BeWizrLibraryView

const useStyles = createUseStyles(theme => ({
  filters: {
    ...layout.tablet({
      width: 264,
      borderRight: [2, 'solid', theme.colors.border.dimmer],
    }),
  },
}))