import { AxiosInstance } from 'axios'
import { createAxios } from '~/axios'
import config from '~/config'

let __bewizrAPI: AxiosInstance | null = null

export function bewizrAPI() {
  return __bewizrAPI
}

export function initBeWizrAPI(authToken: string) {
  __bewizrAPI = createAxios({
    baseURL:   `${config.urls.wizr}/frontend/api/`,
    authToken: authToken,
  })
}