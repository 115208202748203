import {
  CollectionFetchOptions,
  CollectionFetchResponse,
  Database,
  Endpoint,
  EndpointOptions,
} from 'mobx-document'
import socket from 'socket.io-react'
import { Connection } from '~/models'
import ConnectionDocument from './ConnectionDocument'

export default class ConnectionsEndpoint extends Endpoint<ConnectionDocument, ConnectionsParams, ConnectionsMeta> {

  public constructor(
    public readonly scope: ConnectionScope,
    database: Database<ConnectionDocument>,
    options:  EndpointOptions<ConnectionDocument, ConnectionsMeta> = {},
  ) {
    super(database, options)
  }

  public async fetchNextPage() {
    const nextOffset = this.meta && this.meta.nextOffset
    if (nextOffset == null) { return }

    await this.fetch({offset: nextOffset, append: true})
  }

  public async performFetch(
    params: ConnectionsParams,
    options: CollectionFetchOptions,
  ): Promise<CollectionFetchResponse<Connection, ConnectionsMeta> | null> {
    const response = await socket.fetch(`connections:list:${this.scope}`, {
      ...params,
      ...options,
    })

    if (!response.ok) {return response}

    const pack = response.body
    const data = pack.connections.map((raw: any) => Connection.deserialize(raw))
    const meta = pack.meta

    return {data, meta}
  }

}

export type ConnectionScope =
  /** Confirmed connections. */
  | 'confirmed'
  /** Pending (outgoing) requests waiting to be confirmed by the other party.. */
  | 'pending'
  /** Incoming requests waiting to be confirmed by this participant. */
  | 'requests'

export interface ConnectionsParams {
}

export interface ConnectionsMeta {
  total:      number
  nextOffset: number | null
}