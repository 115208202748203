import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { bewizrCoursesStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, Scroller } from '~/ui/components'
import { AppLayoutConfig } from '~/ui/layouts'
import BeWizrCourseView from './BeWizrCourseView'

export interface BeWizrCourseParams {
  slug: string
}

export type Props = RouteComponentProps<BeWizrCourseParams>

const BeWizrCourseScreen = observer('BeWizrCourseScreen', (props: Props) => {

  const {slug} = props.match.params

  const document    = bewizrCoursesStore.courses.document(slug)
  const course      = document.data
  const fetchStatus = document.fetchStatus

  React.useEffect(() => {
    document?.fetch()
  }, [document])

  //------
  // Rendering

  function render() {
    return (
      <>
        <AppLayoutConfig
          title={course?.title}
          fetchStatus={fetchStatus}
        />
        {renderContent()}
      </>
    )
  }

  function renderContent() {
    if (course?.items == null || fetchStatus !== 'done') {
      return renderEmpty()
    }

    return (
      <Scroller>
        <BeWizrCourseView course={course}/>
      </Scroller>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        flex
        status={fetchStatus}
      />
    )
  }

  return render()

})

export default BeWizrCourseScreen