import React from 'react'
import { useTranslation } from 'react-i18next'
import { BeWizrCourse } from '~/models'
import { memo } from '~/ui/component'
import { Center, Label, SVG, Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'

export interface Props {
  course: BeWizrCourse
}

const BeWizrCourseCertificate = memo('BeWizrCourseCertificate', (props: Props) => {

  const {course} = props

  const [t, i18n] = useTranslation('bewizr-courses')

  const date   = course.certificatePassedAt
  const passed = date != null

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (!course.deliverCertificate) { return null }
    return (
      <VBox gap={layout.padding.m}>
        <Label small truncate={false}>
          {t(`progress.certificate.${passed ? 'obtained' : 'not_obtained'}`)}
        </Label>
        {renderButton()}
      </VBox>
    )
  }

  function renderButton() {
    return (
      <ThemeProvider dark={passed}>
        <Tappable enabled={passed} href={course.links.certificatePdf} classNames={[$.BeWizrCourseCertificate, {passed}]}>
          <Center gap={layout.padding.inline.m} flex>
            <SVG
              name='certificate'
              size={layout.icon.xxl}
              color={passed ? colors.fg.light.normal : colors.fg.dimmer}
            />
            {passed && (
              <Label tiny bold>
                {date?.toFormat('d-M-yyyy', {locale: i18n.language})}
              </Label>
            )}
          </Center>
        </Tappable>
      </ThemeProvider>
    )
  }

  return render()

})

export default BeWizrCourseCertificate

export const size = {
  width:  90,
  height: 90,
}

const useStyles = createUseStyles(theme => ({
  BeWizrCourseCertificate: {
    ...size,
    backgroundColor: theme.colors.grayScale.light[1],
    borderRadius:    layout.radius.m,
    '&.passed': {
      backgroundColor: theme.colors.semantic.primary,
    },
  },
}))